<template>
<div>
  <!--面包屑组件 -->
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>分类管理</el-breadcrumb-item>
    <el-breadcrumb-item>名称分类</el-breadcrumb-item>
  </el-breadcrumb>

  <el-card>
    <el-row :gutter="15">
      <el-col :span="8">
        <el-input placeholder="请输入内容" v-model="queryInfo.query" class="input-with-select" clearable @clear="getIsimCateList">
          <el-button slot="append" icon="el-icon-search" @click="getIsimCateList"></el-button>
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-button type="warning" icon="el-icon-plus" @click="showAddDialog()">添加分类</el-button>
      </el-col>
    </el-row>

    <el-table :data="isim_cate_list" border v-loading="loading">
      <el-table-column label="#" type="index"></el-table-column>
      <el-table-column label="分类名称">
        <template slot-scope="scope">
          <div class="uy-text">{{scope.row.isim_cate_name_uy}}</div>
          <div>{{scope.row.isim_cate_name}}</div>
        </template>
      </el-table-column>
      <el-table-column label="排序" width="100">
        <template slot-scope="scope">
          <el-input v-model="scope.row.isim_cate_order_num" @blur="update_order_num(scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="颜色" width="100">
        <template slot-scope="scope">
          <el-tag :color="scope.row.isim_cate_bg_color" size="mini">
            <span style="color:#fff;"> {{scope.row.isim_cate_bg_color}} </span>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="添加时间" prop="isim_cate_create_time"></el-table-column>
      <el-table-column label="操作" width="220">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)">编辑</el-button>
          <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteCate(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page" :page-sizes="[7, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
    </el-pagination>
  </el-card>

  <!-- 添加分类对话框 -->
  <el-dialog title="添加名称分类" :visible.sync="isimDialogVisible" width="30%">
    <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
      <el-form-item label="分类名称" prop="isim_cate_name">
        <el-input v-model="addForm.isim_cate_name"></el-input>
      </el-form-item>
      <el-form-item label="商标图片" prop="sb_image">
        <el-upload class="avatar-uploader" action="https://api.arzu.biz/upload" :headers="headerObj" :show-file-list="false" :on-success="handleImageSuccess" :before-upload="beforeImageUpload">
          <img v-if="imageUrl" :src="imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="维文名称" prop="isim_cate_name_uy">
        <el-input v-model="addForm.isim_cate_name_uy" class="uy-text"></el-input>
      </el-form-item>
      <el-form-item label="背景颜色" prop="isim_cate_bg_color">
        <el-input v-model="addForm.isim_cate_bg_color" class="uy-text"></el-input>
        <el-color-picker v-model="addForm.isim_cate_bg_color"></el-color-picker>
      </el-form-item>
      <el-form-item label="排序" prop="isim_cate_order_num">
        <el-input v-model="addForm.isim_cate_order_num" style="width:100px"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="isimDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="saveIsimCate()">确 定</el-button>
    </span>
  </el-dialog>

  <!-- 编辑分类对话框 -->
  <el-dialog title="编辑名称分类" :visible.sync="editIsimDialogVisible" width="30%">
    <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
      <el-form-item label="分类名称" prop="isim_cate_name">
        <el-input v-model="editForm.isim_cate_name"></el-input>
      </el-form-item>
      <el-form-item label="商标图片" prop="sb_image">
        <el-upload class="avatar-uploader" action="https://api.arzu.biz/upload" :headers="headerObj" :show-file-list="false" :on-success="handleEditImageSuccess" :before-upload="beforeImageUpload">
          <img v-if="imageUrl" :src="imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="维文名称" prop="isim_cate_name_uy">
        <el-input v-model="editForm.isim_cate_name_uy" class="uy-text"></el-input>
      </el-form-item>

      <el-form-item label="背景颜色" prop="isim_cate_bg_color">
        <el-input v-model="editForm.isim_cate_bg_color"></el-input>
        <el-color-picker v-model="editForm.isim_cate_bg_color"></el-color-picker>
      </el-form-item>
      <el-form-item label="排序" prop="isim_cate_order_num">
        <el-input v-model="editForm.isim_cate_order_num" style="width:100px"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="editIsimDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="updateIsimCate()">确 定</el-button>
    </span>
  </el-dialog>
</div>
</template>

<script>
export default {
  data() {
    return {
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 7
      },
      total: 0,
      loading: true,
      addForm: {
        isim_cate_name: '',
        isim_cate_name_uy: '',
        isim_cate_order_num: 0,
        isim_cate_image: '',
        isim_cate_bg_color: ''
      },
      // 上传接口请求头参数
      headerObj: {
        Authorization: window.sessionStorage.getItem('token')
      },
      imageUrl: '',
      editForm: {},
      isim_cate_list: [],
      addFormRules: {
        isim_cate_name: [{
          required: true,
          message: '请输入分类名称',
          trigger: 'blur'
        }],
        isim_cate_name_uy: [{
          required: true,
          message: '请输入维文名称',
          trigger: 'blur'
        }],
        isim_cate_order_num: [{
          required: true,
          message: '排序号不能为空、而且必须是数字',
          trigger: 'blur'
        }]
      },
      editFormRules: {
        isim_cate_name: [{
          required: true,
          message: '请输入分类名称',
          trigger: 'blur'
        }],
        isim_cate_name_uy: [{
          required: true,
          message: '请输入维文名称',
          trigger: 'blur'
        }],
        isim_cate_order_num: [{
          required: true,
          message: '排序号不能为空、而且必须是数字',
          trigger: 'blur'
        }]
      },
      isimDialogVisible: false,
      editIsimDialogVisible: false
    }
  },
  created() {
    this.getIsimCateList()
  },
  methods: {
    async getIsimCateList() {
      const {
        data: res
      } = await this.$http.get('category/isim', {
        params: this.queryInfo
      })
      if (res.code !== 200) {
        this.$message.error('获取失败')
      }
      this.total = res.data.total
      this.isim_cate_list = res.data.data
      this.$message.success('获取成功')
      this.loading = false
    },
    showAddDialog() {
      this.isimDialogVisible = true
    },
    saveIsimCate() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) {
          return this.$message.error('请填写相关信息')
        }
        const {
          data: res
        } = await this.$http.post('category/isim', this.addForm)
        if (res.code !== 200) {
          return this.$message.error('添加失败')
        }
        this.$message.success('添加成功')
        this.getIsimCateList()
        this.isimDialogVisible = false
      })
    },

    async update_order_num(row) {
      const {
        data: res
      } = await this.$http.put('category/update_cate_order/' + row.id, {
        isim_cate_order_num: row.isim_cate_order_num
      })
      if (res.code !== 200) {
        return this.$message.error('更新失败')
      }
      this.$message.success('更新成功')
      this.getIsimCateList()
    },
    async deleteCate(id) {
      const confirmRes = await this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)

      if (confirmRes !== 'confirm') {
        return this.$message.info('取消删除')
      }

      const {
        data: res
      } = await this.$http.delete('category/isim/' + id)
      if (res.code !== 200) {
        return this.$message.error('删除失败')
      }
      this.$message.success('删除成功')
      this.getIsimCateList()
    },
    async showEditDialog(id) {
      const {
        data: res
      } = await this.$http.get('category/isim_info/' + id)
      if (res.code !== 200) {
        this.$message.error('获取数据失败')
      }
      this.editForm = res.data
      this.imageUrl = window.base_image_url + res.data.isim_cate_image
      this.editIsimDialogVisible = true
    },
    updateIsimCate() {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) {
          return this.$message.error('请填写分类名称')
        }
        const {
          data: res
        } = await this.$http.put('category/isim', this.editForm)
        if (res.code !== 201) {
          return this.$message.error('修改失败')
        }
        this.editIsimDialogVisible = false
        this.editForm = {}
        this.getIsimCateList()
        this.$message.success('修改成功')
      })
    },
    handleSizeChange(newPage) {
      this.queryInfo.pagesize = newPage
      this.getIsimCateList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getIsimCateList()
    },
    handleImageSuccess(res, file) {
      // this.uploadForm.imageUrl = URL.createObjectURL(file.raw)
      this.addForm.isim_cate_image = res.save_path
      if (res.code !== 200) {
        this.$message.error('图片上传失败')
      }
      this.imageUrl = window.base_image_url + res.save_path
      this.$message.success(res.msg)
    },
    handleEditImageSuccess(res, file) {
      // this.uploadForm.imageUrl = URL.createObjectURL(file.raw)
      this.editForm.isim_cate_image = res.save_path
      if (res.code !== 200) {
        this.$message.error('图片上传失败')
      }
      this.imageUrl = window.base_image_url + res.save_path
      this.$message.success(res.msg)
    },
    beforeImageUpload(file) {
      const isImage = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isImage) {
        this.$message.error('上传歌曲海报只能是 JPG|PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传歌曲海报大小不能超过 2MB!')
      }
      return isImage && isLt2M
    }
  }
}
</script>

<style scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

.avatar {
  width: 80px;
  height: 80px;
  display: block;
  background-color: #606266;
}
</style>
