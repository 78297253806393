<template>
<div>
  <!--面包屑组件 -->
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>内容管理</el-breadcrumb-item>
    <el-breadcrumb-item>添加文章</el-breadcrumb-item>
  </el-breadcrumb>
  <el-card>
    <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="文章名称" prop="article_title">
            <el-input v-model="addForm.article_title"></el-input>
          </el-form-item>
          <el-form-item label="维文名称" prop="article_title_uy">
            <el-input v-model="addForm.article_title_uy"></el-input>
          </el-form-item>
          <el-form-item label="文章封面">
            <el-upload class="avatar-uploader" action="https://api.arzu.biz/upload" :headers="headerObj" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="文章分类">
            <el-select v-model="addForm.article_cate_id" placeholder="请选择">
              <el-option v-for="item in cateList" :key="item.id" :label="item.article_category_name + ' - ' + item.article_category_name_uy" :value="item.id" style="cate_list">
                <span>{{ item.article_category_name }}</span>
                <span style="float:right;font-family:alkatip basma tom">{{ item.article_category_name_uy }}</span>
              </el-option>
            </el-select>
          </el-form-item>
            <el-form-item label="VIP阅读" prop="article_is_vip">
              <el-radio-group v-model="addForm.article_is_vip">
                <el-radio :label='0'>关闭</el-radio>
                <el-radio :label='1'>开启</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="付费阅读" prop="article_is_buy">
              <el-radio-group v-model="addForm.article_is_buy" @change="radio_edit_buy_change">
                <el-radio :label="0">关闭</el-radio>
                <el-radio :label="1">开启</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="价格(元)">
                <el-input v-model="addForm.article_price" placeholder="文章价格:例如 3" type="number"></el-input>
            </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="文章内容" prop="article_content">
            <quill-editor v-model="addForm.article_content" :options="editorOption"></quill-editor>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="维文内容" prop="article_content_uy">
            <quill-editor v-model="addForm.article_content_uy" class="ql-editor-uy" :options="editorOption_uy"></quill-editor>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="8">
          <el-button type="primary" @click="saveArticle">保存文章</el-button>
          <el-button>重置</el-button>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</div>
</template>

<script>
import '../../global.js'
export default {
  data() {
    return {
      isShowEditBuyInput: false,
      addForm: {
        article_title: '',
        article_title_uy: '',
        article_cate_id: '',
        article_image: '',
        article_content: '',
        article_is_buy: '0',
        article_is_vip: '0',
        article_price: '',
        article_content_uy: ''
      },
      // 上传接口请求头参数
      headerObj: {
        Authorization: window.sessionStorage.getItem('token')
      },
      imageUrl: '',
      cateList: [],
      addFormRules: {
        article_title: [
          { required: true, message: '请输入文章标题', trigger: 'blur' }
        ],
        article_title_uy: [
          { required: true, message: '请输入维文标题', trigger: 'blur' }
        ],
        article_content: [
          { required: true, message: '请输入文章内容', trigger: 'blur' }
        ],
        article_content_uy: [
          { required: true, message: '请输入维文内容', trigger: 'blur' }
        ]
      },
      editorOption: {
        placeholder: '请输入内容',
        theme: 'snow'
      },
      editorOption_uy: {
        placeholder: 'مەزمۇننى كىرگۈزۈڭ ...',
        theme: 'snow'
      }
    }
  },
  created() {
    this.getCateList()
  },
  methods: {
    async getCateList() {
      const {
        data: res
      } = await this.$http.get('category/articleAll')
      if (res.code !== 200) {
        this.$message.error('分类数据获取失败')
      }
      this.cateList = res.data
    },
    radio_edit_buy_change() {
      this.isShowEditBuyInput = this.addForm.article_is_buy === '1'
    },
    handleAvatarSuccess(res, file) {
      // this.uploadForm.imageUrl = URL.createObjectURL(file.raw)
      this.addForm.article_image = res.save_path
      if (res.code !== 200) {
        this.$message.error('图片上传失败')
      }
      this.imageUrl = window.base_image_url + res.save_path
      this.$message.success(res.msg)
    },
    beforeAvatarUpload(file) {
      const isImage = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isImage) {
        this.$message.error('上传歌曲海报只能是 JPG|PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传歌曲海报大小不能超过 2MB!')
      }
      return isImage && isLt2M
    },
    saveArticle() {
      this.$refs.addFormRef.validate(async valid => {
        if (this.imageUrl === '') {
          return this.$message.error('请上传文章封面')
        }
        if (this.addForm.article_cate_id === '') {
          return this.$message.error('请选择文章分类')
        }
        const { data: res } = await this.$http.post('article', this.addForm)
        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.$router.push('/article')
      })
    }
  }
}
</script>

<style scoped>
.cate_list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 10px 0;
  font-family: 'alkatip basma tom';
}

.el-select {
  width: 70%;
}
</style>
