import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'
import Welcome from '../views/home/welcome'
import Login from '../views/Login'
import Article from '../views/article/Article'
import ArticleAdd from '../views/article/AddArticle'
import ArticleEdit from '../views/article/EditArticle'
import ArticleCategory from '../views/category/ArticleCategory'
import IsimCategory from '../views/category/IsimCategory'
import ShangbiaoCategory from '../views/category/ShangbiaoCategory'
import Menus from '../views/wechat/Menus.vue'
import Banner from '../views/banner/Banner'
import ShangbiaoAdd from '../views/shangbiao/AddShangbiao'
import ShangBiao from '@/views/shangbiao/ShangBiao'
import EditShangBiao from '../views/shangbiao/EditShangbiao'
import Isim from '../views/isim/Isim'
import EditIsim from '../views/isim/EditIsim'
import IsimAdd from '../views/isim/AddIsim'
import SysInfo from '../views/system/Info'
import Order from '../views/order/order'
import Users from '../views/users/Users'

import Vip from '../views/system/Vip'
import About from '../views/wechat/About'

Vue.use(VueRouter)

const routes = [
  { path: '/login', name: 'Login', component: Login },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    redirect: '/welcome',
    children: [
      { path: '/welcome', component: Welcome },
      { path: '/article', component: Article },
      { path: '/article/edit', component: ArticleEdit },
      { path: '/article/add', component: ArticleAdd },
      { path: '/category/article', component: ArticleCategory },
      { path: '/category/isim', component: IsimCategory },
      { path: '/category/sb', component: ShangbiaoCategory },
      { path: '/wechat/menus', component: Menus },
      { path: '/wechat/about', component: About },
      { path: '/banner', component: Banner },
      { path: '/sb/add', component: ShangbiaoAdd },
      { path: '/sb/edit', component: EditShangBiao },
      { path: '/sb', component: ShangBiao },
      { path: '/isim', component: Isim },
      { path: '/isim/add', component: IsimAdd },
      { path: '/isim/edit', component: EditIsim },
      { path: '/sys/info', component: SysInfo },
      { path: '/user/order', component: Order },
      { path: '/user', component: Users },
      { path: '/sys/vip', component: Vip }
    ]
  },
  { path: '/about', name: 'About' }
]

const router = new VueRouter({
  routes
})

// 加载路由导航守卫
router.beforeEach((to, from, next) => {
  document.title = to.title || 'ARZU 内容管理系统'
  // to 将要访问的路径
  // 如果用户访问的登录页面、直接放行
  if (to.path === '/login') return next()
  const tokenStr = window.sessionStorage.getItem('token')
  // 没有token、强制跳转到登录页面
  if (!tokenStr) {
    return next('/login')
  }
  next()
})

export default router
