<template>
<div>
    <Breadcrumb msg1="小程序" msg2="VIP配置" />
    <el-card>
        <el-row>
            <el-col :span="4">
                <el-button type="warning" icon="el-icon-plus" @click="add()">新增配置</el-button>
            </el-col>
        </el-row>
        <el-table :data="vipList" v-loading="loading">
            <el-table-column label="标题" sortable prop="title"></el-table-column>
            <el-table-column label="期限(月)" sortable prop="month" width="120"></el-table-column>
            <el-table-column label="原价(元)" sortable prop="price" width="150"></el-table-column>
            <el-table-column label="折扣价(元)" sortable prop="price_discount" width="150"></el-table-column>
            <el-table-column label="状态" sortable width="100">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.status === 0" type="info"> 关闭 </el-tag>
                    <el-tag v-if="scope.row.status === 1" type="success"> 启用 </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="更新时间" sortable prop="update_time" width="250"></el-table-column>
            <el-table-column label="操作" width="250">
                <template slot-scope="scope">
                    <el-button type="primary" icon="el-icon-edit" size="mini" @click="edit(scope.row.id)">编辑</el-button>
                    <el-button type="danger" icon="el-icon-delete" size="mini" @click="del(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-card>

    <el-dialog title="添加VIP配置" :visible.sync="addDialogVisible" width="30%">
        <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" label-width="100px">
            <el-form-item label="标题" prop="title" class="uy-text">
                <el-input v-model="addForm.title"></el-input>
            </el-form-item>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="期限(月)" prop="month">
                        <el-input v-model="addForm.month" type="number"></el-input>
                    </el-form-item>
                    <el-form-item label="原价(元)" prop="price">
                        <el-input v-model="addForm.price" type="number"></el-input>
                    </el-form-item>
                    <el-form-item label="折扣价(元)" prop="price_discount">
                        <el-input v-model="addForm.price_discount" type="number"></el-input>
                    </el-form-item>
                    <el-form-item label="开启">
                        <el-switch v-model="addForm.status"></el-switch>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="addDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="saveAddForm">确 定</el-button>
        </span>
    </el-dialog>

    <el-dialog title="编辑VIP配置" :visible.sync="editDialogVisible" width="30%">
        <el-form :model="editForm" ref="editFormRef" :rules="editFormRules" label-width="100px">
            <el-form-item label="标题" prop="title">
                <el-input v-model="editForm.title"></el-input>
            </el-form-item>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="期限(月)" prop="month">
                        <el-input v-model="editForm.month" type="number"></el-input>
                    </el-form-item>
                    <el-form-item label="原价(元)" prop="price">
                        <el-input v-model="editForm.price" type="number"></el-input>
                    </el-form-item>
                    <el-form-item label="折扣价(元)" prop="price_discount">
                        <el-input v-model="editForm.price_discount" type="number"></el-input>
                    </el-form-item>
                    <el-form-item label="开启">
                        <el-switch v-model="editForm.status"></el-switch>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="editDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="saveEditForm">确 定</el-button>
        </span>
    </el-dialog>

</div>
</template>

<script>
export default {
    data() {
        return {
            total: 0,
            loading: true,
            vipList: [],
            addDialogVisible: false,
            editDialogVisible: false,
            addForm: {
                title: '',
                month: '',
                price: '',
                price_discount: '',
                status: true
            },
            editForm: {},
            addFormRules: {
                title: [{
                    required: 'true',
                    message: '请填写标题',
                    trigger: 'blur'
                }],
                month: [{
                    required: 'true',
                    message: '请填写期限',
                    trigger: 'blur'
                }],
                price: [{
                    required: 'true',
                    message: '请填写价格',
                    trigger: 'blur'
                }],
                price_discount: [{
                    required: 'true',
                    message: '请填写折扣价',
                    trigger: 'blur'
                }]
            },
            editFormRules: {
                title: [{
                    required: 'true',
                    message: '请填写标题',
                    trigger: 'blur'
                }],
                month: [{
                    required: 'true',
                    message: '请填写期限',
                    trigger: 'blur'
                }],
                price: [{
                    required: 'true',
                    message: '请填写价格',
                    trigger: 'blur'
                }],
                price_discount: [{
                    required: 'true',
                    message: '请填写折扣价',
                    trigger: 'blur'
                }]
            }
        }
    },
    created() {
        this.getVipList()
    },
    methods: {
        async getVipList() {
            const {
                data: res
            } = await this.$http.get('vipconfig')
            if (res.code !== 200) {
                return this.$message.error(res.msg)
            }

            this.vipList = res.data
            this.$message.success(res.msg)
            this.loading = false
        },
        add() {
            this.addDialogVisible = true
        },
        async edit(id) {
            const {
                data: res
            } = await this.$http.get('vipconfig/' + id)
            if (res.code !== 200) {
                return this.$message.error(res.msg)
            }
            this.editForm = res.data
            this.editDialogVisible = true
        },
        async del(id) {
            const confirmRes = await this.$confirm('此操作将永久删除该内容, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).catch(err => err)

            if (confirmRes !== 'confirm') {
                return this.$message.info('取消删除')
            }
            const {
                data: res
            } = await this.$http.delete('vipconfig/' + id)
            if (res.code !== 201) {
                return this.$message.error(res.msg)
            }
            this.$message.success(res.msg)
            this.getVipList()
        },
        async saveAddForm() {
            this.$refs.addFormRef.validate(async valid => {
                if (!valid) {
                    return this.$message.error('请填写相关信息')
                }
                const {
                    data: res
                } = await this.$http.post('vipconfig', this.addForm)
                if (res.code !== 200) {
                    return this.$message.error(res.msg)
                }
                this.getVipList()
                this.$message.success(res.msg)
                this.addDialogVisible = false
            })
        },
        saveEditForm() {
            this.$refs.editFormRef.validate(async valid => {
                if (!valid) {
                    return this.$message.error('请填写相关信息')
                }
                const {
                    data: res
                } = await this.$http.put('vipconfig', this.editForm)
                if (res.code !== 200) {
                    return this.$message.error(res.msg)
                }
                this.getVipList()
                this.$message.success(res.msg)
                this.editDialogVisible = false
            })
        }
    }
}
</script>

<style lang="less" scoped>
.avatar-uploader .avatar-uploader-icon {
    width: 110px;
    height: 110px;
    line-height: 110px;
}

.avatar {
    width: 110px;
    height: 110px;
    line-height: 110px;
}

.el-form-item__content {
    line-height: 24px !important;
}

.el-row {
    margin-bottom: 10px;
}
</style>
