<template>
<div class="">
  <BreadCrumb msg1="商标管理" msg2="添加商标" />
  <el-card>
    <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="120px" class="demo-ruleForm">
      <el-row>
        <el-col :span="16">
          <el-form-item label="商标名称" prop="sb_name">
            <el-input v-model="addForm.sb_name"></el-input>
          </el-form-item>
          <el-form-item label="维文名称" prop="sb_name_uy">
            <el-input v-model="addForm.sb_name_uy"></el-input>
          </el-form-item>
          <el-form-item label="商标分类" prop="sb_cate_id">
            <el-select v-model="addForm.sb_cate_id" placeholder="请选择分类" style="width:30%;">
              <el-option v-for="item in sb_cate_list" :key="item.id" :label="item.sb_cate_name + ' - ' + item.sb_cate_name_uy" :value="item.id" style="cate_list">
                <span>{{ item.sb_cate_name }}</span>
                <span style="float:right;" class="uy-text">{{ item.sb_cate_name_uy }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="注册号码" prop="sb_register_id">
            <el-input v-model="addForm.sb_register_id" style="width:50%;"></el-input>
          </el-form-item>
          <el-form-item label="商标价格" prop="sb_price">
            <el-input v-model="addForm.sb_price" style="width:150px;" placeholder="0.00">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="商标图片" prop="sb_image">
            <el-upload class="avatar-uploader" action="https://api.arzu.biz/upload" :headers="headerObj" :show-file-list="false" :on-success="handleImageSuccess" :before-upload="beforeImageUpload">
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="使用范围" prop="sb_usearea">
            <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="addForm.sb_usearea">
            </el-input>
          </el-form-item>
          <el-form-item label="使用范围(维文)" prop="sb_usearea_uy">
            <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="addForm.sb_usearea_uy">
            </el-input>
          </el-form-item>
          <el-form-item label="有效期" prop="sb_expire_time">
            <el-date-picker v-model="addForm.sb_expire_time" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="备注" prop="sb_beizhu">
            <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="addForm.sb_beizhu">
            </el-input>
          </el-form-item>
          <el-form-item label="备注(维文)" prop="sb_beizhu_uy">
            <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="addForm.sb_beizhu_uy">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row align="center">
        <el-col :offset="12">
          <el-button type="primary" @click="savaAddForm">保存</el-button>
          <el-button>重置</el-button>
        </el-col>
      </el-row>
    </el-form>
  </el-card>
</div>
</template>

<script>
import BreadCrumb from '../../components/Breadcrumb'
export default {
  name: 'AddShangbiao',
  components: {
    BreadCrumb
  },
  data() {
    return {
      addForm: {
        sb_name: '',
        sb_name_uy: '',
        sb_cate_id: '',
        sb_register_id: '',
        sb_price: '',
        sb_image: '',
        sb_usearea: '',
        sb_usearea_uy: '',
        sb_expire_time: '',
        sb_beizhu: '',
        sb_beizhu_uy: ''
      },
      // 上传接口请求头参数
      headerObj: {
        Authorization: window.sessionStorage.getItem('token')
      },
      imageUrl: '',
      addFormRules: {
        sb_name: [
          { required: true, message: '请输入商标名称', trigger: 'blur' }
        ],
        sb_name_uy: [
          { required: true, message: '请输入维文名称', trigger: 'blur' }
        ],
        sb_cate_id: [
          { required: true, message: '请选择商标分类', trigger: 'blur' }
        ],
        sb_register_id: [
          { required: true, message: '请输入商标注册号码', trigger: 'blur' }
        ],
        sb_price: [
          { required: true, message: '请输入商标价格', trigger: 'blur' }
        ],
        sb_usearea: [
          { required: true, message: '请输入商标使用范围', trigger: 'blur' }
        ],
        sb_usearea_uy: [
          { required: true, message: '请输入商标维文的使用范围', trigger: 'blur' }
        ],
        sb_expire_time: [
          { required: true, message: '请选择商标的有效期', trigger: 'blur' }
        ]
      },
      sb_cate_list: []
    }
  },
  created() {
    this.getSbCateList()
  },
  methods: {
    savaAddForm() {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) {
          return this.$message.error('请填写商标信息')
        }
        if (this.addForm.sb_image === '') {
          return this.$message.error('请上传商标图片')
        }
        const { data: res } = await this.$http.post('shangbiao', this.addForm)
        if (res.code !== 200) {
          this.$message.error('添加失败')
        }
        this.$message.success('添加成功')
        this.$router.push('/sb')
      })
    },
    // 获取商标分类数据
    async getSbCateList() {
      const { data: res } = await this.$http.get('category/shangbiaoAll')
      if (res.code !== 200) {
        this.$message.error('分类数据获取失败')
      }

      this.sb_cate_list = res.data
    },
    handleImageSuccess(res, file) {
      // this.uploadForm.imageUrl = URL.createObjectURL(file.raw)
      this.addForm.sb_image = res.save_path
      if (res.code !== 200) {
        this.$message.error('图片上传失败')
      }
      this.imageUrl = window.base_image_url + res.save_path
      this.$message.success(res.msg)
    },
    beforeImageUpload(file) {
      const isImage = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isImage) {
        this.$message.error('上传歌曲海报只能是 JPG|PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传歌曲海报大小不能超过 2MB!')
      }
      return isImage && isLt2M
    }
  }
}
</script>

<style scoped>
</style>
