<template>
<div>
  <!--面包屑组件 -->
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
    <el-breadcrumb-item>小程序管理</el-breadcrumb-item>
    <el-breadcrumb-item>添加文章</el-breadcrumb-item>
  </el-breadcrumb>
  <el-card>
    <el-row :gutter="15">
      <el-col :span="8">
        <el-input placeholder="请输入内容" v-model="queryInfo.query" class="input-with-select" clearable @clear="getMenusList">
          <el-button slot="append" icon="el-icon-search" @click="getMenusList"></el-button>
        </el-input>
      </el-col>
      <el-col :span="4">
        <el-button type="warning" icon="el-icon-plus" @click="showMenuDialog()">添加菜单</el-button>
      </el-col>
    </el-row>

    <el-table :data="menusList" border v-loading="loading">
      <el-table-column label="#" type="index"></el-table-column>
      <el-table-column label="菜单图片" width="120">
        <template slot-scope="scope">
          <img class="menu-image" :src="base_image_url + scope.row.menu_image_url" />
        </template>
      </el-table-column>
      <el-table-column label="维文名称">
        <template slot-scope="scope">
          <div class="uy-text">{{scope.row.menu_name_uy}}</div>
          <div>{{scope.row.menu_name}}</div>
        </template>
      </el-table-column>
      <el-table-column label="跳转类型">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.menu_leixing === 1" type="success">文章</el-tag>
          <el-tag v-if="scope.row.menu_leixing === 2">列表</el-tag>
          <el-tag v-if="scope.row.menu_leixing === 3" type="warning">路径</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="跳转ID" prop="menu_resource_id"></el-table-column>
      <el-table-column label="排序" width="100">
        <template slot-scope="scope">
          <el-input v-model="scope.row.menu_order_num" @blur="update_order_num(scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="维文名称">
        <template slot-scope="scope">
          <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditMenuDialog(scope.row.id)">编辑</el-button>
          <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteMenu(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page" :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
  </el-card>

  <!-- 添加菜单 -->
  <el-dialog title="添加菜单" :visible.sync="menuDialogVisible" width="40%">
    <el-form :model="addMenuForm" :rules="addMenuFormRules" ref="addMenuFormRef" label-width="100px">
      <el-form-item label="菜单名称" prop="menu_name">
        <el-input v-model="addMenuForm.menu_name"></el-input>
      </el-form-item>
      <el-form-item label="维文名称" prop="menu_name_uy" class="uy-text">
        <el-input v-model="addMenuForm.menu_name_uy"></el-input>
      </el-form-item>
      <el-form-item label="菜单图片">
        <el-upload class="avatar-uploader" action="https://api.arzu.biz/upload" :headers="headerObj" :show-file-list="false" :on-success="handleImageIconSuccess" :before-upload="beforeImageIconUpload">
          <img v-if="imageUrl" :src="imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="排序" prop="menu_order_num">
        <el-input v-model="addMenuForm.menu_order_num"></el-input>
      </el-form-item>
      <el-form-item label="跳转类型">
        <el-radio-group v-model="addMenuForm.leixing" @change="lxChange">
          <el-radio :label="1">文章</el-radio>
          <el-radio :label="2">列表</el-radio>
          <el-radio :label="3">路径</el-radio>
        </el-radio-group>
        <el-alert title="资源ID请填写所选类型对应的ID、否则会出现找不到资源的问题" type="warning" show-icon>
        </el-alert>
      </el-form-item>
      <el-form-item :label="resorce_name" prop="menu_resource_id">
        <el-input v-model="addMenuForm.menu_resource_id"></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="menuDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="saveMenuForm">确 定</el-button>
    </span>
  </el-dialog>

  <!-- 编辑菜单 -->
  <el-dialog title="编辑菜单" :visible.sync="menuEditDialogVisible" width="40%">
    <el-form :model="editMenuForm" :rules="addMenuFormRules" ref="editMenuFormRef" label-width="100px">
      <el-form-item label="菜单名称" prop="menu_name">
        <el-input v-model="editMenuForm.menu_name"></el-input>
      </el-form-item>
      <el-form-item label="维文名称" prop="menu_name_uy">
        <el-input v-model="editMenuForm.menu_name_uy" class="uy-text"></el-input>
      </el-form-item>
      <el-form-item label="菜单图片">
        <el-upload class="avatar-uploader" action="https://api.arzu.biz/upload" :headers="headerObj" :show-file-list="false" :on-success="handleImageEditIconSuccess" :before-upload="beforeImageIconUpload">
          <img v-if="imageUrl" :src="imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="排序" prop="menu_order_num">
        <el-input v-model="editMenuForm.menu_order_num"></el-input>
      </el-form-item>
      <el-form-item label="跳转类型">
        <el-radio-group v-model="editMenuForm.menu_leixing" @change="lxChange">
          <el-radio :label="1">文章</el-radio>
          <el-radio :label="2">列表</el-radio>
          <el-radio :label="3">路径</el-radio>
        </el-radio-group>
        <el-alert title="资源ID请填写所选类型对应的ID、否则会出现找不到资源的问题" type="warning" show-icon>
        </el-alert>
      </el-form-item>
      <el-form-item :label="resorce_name" prop="menu_resource_id">
        <el-input v-model="editMenuForm.menu_resource_id"></el-input>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="menuEditDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="updateEditMenuForm">确 定</el-button>
    </span>
  </el-dialog>
</div>
</template>

<script>
export default {
  data() {
    return {
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 7
      },
      resorce_name: '资源ID',
      total: 0,
      loading: true,
      base_image_url: window.base_image_url,
      menuDialogVisible: false,
      menuEditDialogVisible: false,
      addMenuForm: {
        menu_name: '',
        menu_name_uy: '',
        menu_image_url: '',
        menu_order_num: '0',
        leixing: 1,
        menu_resource_id: ''
      },
      editMenuForm: {},
      menusList: [],
      imageUrl: '',
      // 上传接口请求头参数
      headerObj: {
        Authorization: window.sessionStorage.getItem('token')
      },
      addMenuFormRules: {
        menu_name: [{
            required: true,
            message: '请填写菜单名称',
            trigger: 'blur'
          },
          {
            min: 2,
            max: 8,
            message: '长度在 2 到 8 个字符',
            trigger: 'blur'
          }
        ],
        menu_name_uy: [{
            required: true,
            message: '请填写维文名称',
            trigger: 'blur'
          },
          {
            min: 2,
            max: 50,
            message: '长度在 2 到 50 个字符',
            trigger: 'blur'
          }
        ],
        menu_resource_id: [{
          required: true,
          message: '请填写资源ID',
          trigger: 'blur'
        }],
        menu_order_num: [{
          required: true,
          message: '请填写排序号码、数字越大越靠前显示',
          trigger: 'blur'
        }]
      }
    }
  },
  created() {
    this.getMenusList()
  },
  methods: {
    async getMenusList() {
      const {
        data: res
      } = await this.$http.get('menus', {
        params: this.queryInfo
      })
      if (res.code !== 200) {
        this.$message.error('数据获取失败')
      }
      this.total = res.data.total
      this.menusList = res.data.data
      this.$message.success('获取成功')
      this.loading = false
    },
    showMenuDialog() {
      this.menuDialogVisible = true
    },
    async showEditMenuDialog(id) {
      const {
        data: res
      } = await this.$http.get('menus/' + id)
      if (res.code !== 200) {
        this.$message.error('数据获取失败')
      }
      this.editMenuForm = res.data
      this.imageUrl = window.base_image_url + res.data.menu_image_url
      this.$message.success('数据获取成功')
      this.menuEditDialogVisible = true
    },
    handleImageIconSuccess(res, file) {
      this.addMenuForm.menu_image_url = res.save_path
      if (res.code !== 200) {
        return this.$message.error('图片上传失败')
      }
      this.imageUrl = window.base_image_url + res.save_path
      this.$message.success(res.msg)
    },

    handleImageEditIconSuccess(res, file) {
      if (res.code !== 200) {
        return this.$message.error('图片上传失败')
      }
      this.editMenuForm.menu_image_url = res.save_path
      this.imageUrl = window.base_image_url + res.save_path
      this.$message.success(res.msg)
    },

    beforeImageIconUpload(file) {
      const isImage = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt1M = file.size / 1024 / 1024 < 1

      if (!isImage) {
        this.$message.error('上传歌曲海报只能是 JPG|PNG 格式!')
      }
      if (!isLt1M) {
        this.$message.error('上传歌曲海报大小不能超过 1MB!')
      }
      return isImage && isLt1M
    },
    saveMenuForm() {
      this.$refs.addMenuFormRef.validate(async valid => {
        if (!valid) {
          return false
        }
        if (this.addMenuForm.menu_image_url === '') {
          return this.$message.error('请上传菜单图片')
        }
        const {
          data: res
        } = await this.$http.post('menus', this.addMenuForm)
        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.menuDialogVisible = false
        this.addMenuForm = {}
        this.addMenuForm.menu_image_url = ''
        this.getMenusList()
      })
    },
    updateEditMenuForm() {
      this.$refs.editMenuFormRef.validate(async valid => {
        if (!valid) {
          return this.$message.error('请填写分类名称')
        }
        const {
          data: res
        } = await this.$http.put('menus', this.editMenuForm)
        if (res.code !== 201) {
          return this.$message.error('修改失败')
        }
        this.editMenuForm = {}
        this.getMenusList()
        this.menuEditDialogVisible = false
        this.$message.success('修改成功')
      })
    },
    handleSizeChange(newPage) {
      this.queryInfo.pagesize = newPage
      this.getMenusList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getMenusList()
    },
    lxChange: function (val) {
      if (val === 3) {
        this.resorce_name = '跳转路径'
      } else {
        this.resorce_name = '资源ID'
      }
    },
    async update_order_num(row) {
      const {
        data: res
      } = await this.$http.put('menus/update_order/' + row.id, {
        menu_order_num: row.menu_order_num
      })
      if (res.code !== 200) {
        return this.$message.error('更新失败')
      }
      this.$message.success('更新成功')
      this.getMenusList()
    },
    async deleteMenu(id) {
      const confirmRes = await this.$confirm('此操作将永久删除该菜单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)

      if (confirmRes !== 'confirm') {
        return this.$message.info('取消删除')
      }

      const {
        data: res
      } = await this.$http.delete('menus/' + id)
      if (res.code !== 200) {
        return this.$message.error('删除失败')
      }
      this.$message.success('删除成功')
      this.getMenusList()
    }
  }
}
</script>

<style scoped>
.el-alert {
  width: 100%;
  padding: 0px 5px;
  margin: 0;
  box-sizing: border-box;
  border-radius: 4px;
}

.menu-image {
  width: 40px;
  height: 40px;
}
</style>
