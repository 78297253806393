<template>
<div>
    <breadcrumb param1="订单" param2="充值记录" />
    <el-card>
        <el-table :data="payOrder">
            <el-table-column type="index" label="#" width="50"></el-table-column>
            <el-table-column label="用户" prop="nickname" width="150"></el-table-column>
            <el-table-column label="金额(元)" prop="amount" width="100"></el-table-column>
            <el-table-column label="购买项目" prop="title" width="120"></el-table-column>
            <el-table-column label="付款状态" width="150">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.pay_status === 1" type="success" size="mini">已付款</el-tag>
                    <el-tag v-if="scope.row.pay_status === 0" type="info" size="mini">取消付款</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="流水编号" prop="transaction_id" min-width="300"></el-table-column>
            <el-table-column label="时间" width="300">
                <template slot-scope="scope">
                    <div>创建时间：{{scope.row.create_time}}</div>
                    <div>付款时间：{{scope.row.update_time}}</div>
                </template>
            </el-table-column>
            <el-table-column label="操作" min-width="300">
                <template slot-scope="scope">
                    <el-row>
                        <!-- <el-button type="primary" icon="el-icon-eye" size="mini" @click="showEditDialog(scope.row.id)">详情</el-button> -->
                        <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteOrder(scope.row.id)">删除</el-button>
                    </el-row>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.page" :page-sizes="[10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </el-card>
</div>
</template>

<script>
export default {
    data() {
        return {
            payOrder: [],
            queryInfo: {
                query: '',
                page: 1,
                pagesize: 10
            },
            total: 0
        }
    },
    created () {
        this.getPayOrder()
    },
    methods: {
        async getPayOrder() {
            const {
                data: res
            } = await this.$http.get('order', {
                params: this.queryInfo
            })
            if (res.code !== 200) {
                return this.$message.error(res.msg)
            }
            this.payOrder = res.data.data
            this.total = res.data.total
            console.log(res.data.data)
        },
        showEditDialog() {},
        async deleteOrder(id) {
            const confirmRes = await this.$confirm(
                '此操作将永久删除该订单, 是否继续?',
                '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).catch(err => err)

            if (confirmRes !== 'confirm') {
                return this.$message.info('取消删除')
            }

            const {
                data: res
            } = await this.$http.delete('order/' + id)
            if (res.code !== 201) {
                return this.$message.error(res.msg)
            }
            this.$message.success(res.msg)
            this.getPayOrder()
        },
        handleSizeChange(newPage) {
            this.queryInfo.pagesize = newPage
            this.getPayOrder()
        },
        handleCurrentChange(newPage) {
            this.queryInfo.page = newPage
            this.getPayOrder()
        }
    }
}
</script>

<style scoped>

</style>
