<template>
  <div>
    <Breadcrumb msg1="小程序" msg2="关于小程序" />
    <el-card>
      <el-row>
        <el-col :span="12">
          <el-form
            :model="aboutForm"
            ref="aboutFormRef"
            :rules="aboutFormRules"
            label-width="60px"
          >
            <el-form-item label="标题" prop="about_title">
              <el-input v-model="aboutForm.about_title" class="uy-text">
              </el-input>
            </el-form-item>
            <el-form-item label="内容" prop="about_content">
              <quill-editor
                ref="myTextEditor"
                v-model="aboutForm.about_content"
                :options="editorOption"
                class="ql-editor-uy"
              ></quill-editor>
            </el-form-item>
            <el-button type="primary" @click="onSave">保存</el-button>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      aboutForm: {
        about_title: '',
        about_content: ''
      },
      aboutFormRules: {
        about_title: [
          { required: 'true', message: '请填写标题', trigger: 'blur' }
        ]
      },
      editorOption: {
        placeholder: ' ئەپچاق ھەققىدە... '
      }
    }
  },
  created() {
    this.getAbout()
  },
  methods: {
    async getAbout() {
      const { data: res } = await this.$http.get('about')
      if (res.code !== 200) {
        this.$message.error('获取失败')
      }
      console.log(res)
      this.aboutForm = res.data
    },
    onSave() {
      this.$refs.aboutFormRef.validate(async valid => {
        if (!valid) {
          return this.$message.error('请填写相关信息')
        }
        const { data: res } = await this.$http.put('about', this.aboutForm)
        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
      })
    }
  }
}
</script>
<style scoped>
.ql-editor-uy .ql-editor {
  font-family: "alkatip basma tom";
  direction: rtl;
  text-align: right;
}
</style>
